<template>
  <GuideStep v-if="reportStore.entityDetail?.guideStep < 1" type="TRANSACTIONS" />
  <template v-else>
    <Header
      ref="transactionsHeaderRef"
      type="TRANSACTIONS"
      :title="t('project.nav.transactions')"
      :updated-on="updatedOn"
      :show-regenerate="true"
      :show-regenerate-button="true"
    />

    <HeaderTabs :duplicate-total="utils.formatNumber(duplicateTotal)" :review-total="utils.formatNumber(reviewTotal)" />
    <template v-if="reviewTotal">
      <TransactionScreening table-type="forReview" @onCheckBatchTransactions="onCheckBatchTransactions" />

      <ProcessTable ref="processTableRef" type="review" :height="tableHeight" />
    </template>

    <EmptyResult v-else :img-src="transactionsDoneImg" width="200px" height="200px">
      <template #content>
        <p class="elv-transactions-review-done__title">{{ t('report.wellDone') }}!</p>
        <p class="elv-transactions-review-done__desc">
          {{ t('report.transactionsCategorized') }}
        </p>
      </template>
    </EmptyResult>
  </template>
</template>
<script lang="ts" setup>
import { find } from 'lodash-es'
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
// import { emitter } from '@/lib/mitt'
// eslint-disable-next-line no-unused-vars
import ReportsApi from '@/api/ReportsApi'
import Header from '../components/Header.vue'
import GuideStep from '../components/GuideStep.vue'
import HeaderTabs from './components/HeaderTabs.vue'
import EmptyResult from '@/components/EmptyResult.vue'
import { useGlobalStore } from '@/stores/modules/global'
import ProcessTable from '../components/ProcessTable.vue'
import TransactionScreening from './components/Screening.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import transactionsDoneImg from '@/assets/img/reports/transactions-done.png'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const globalStore = useGlobalStore()
const tableConfigStore = useReportsTableConfigStore()
const processTableRef = ref()
const transactionsHeaderRef = ref()
const loading = ref(false)

const updatedOn = computed(() => {
  const dateTime = processTableRef.value?.groupLitsData?.listData?.fromDate
    ? `From ${utils.dateFormatting(
        processTableRef.value?.groupLitsData?.listData?.fromDate,
        'date'
      )} to ${utils.dateFormatting(processTableRef.value?.groupLitsData?.listData?.toDate, 'date')}`
    : ''
  return dateTime
})

const tableHeight = computed(() => {
  if (globalStore.showUpGradeInfo) {
    return transactionsHeaderRef.value?.isShowRegeneratedInfo ? `calc(100vh - 281px)` : `calc(100vh - 233px)`
  }
  return transactionsHeaderRef.value?.isShowRegeneratedInfo ? `calc(100vh - 250px)` : `calc(100vh - 202px)`
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const duplicateTotal = computed(() => {
  return find(reportStore.issueList, { type: 'DUPLICATE' })?.count ?? 0
})

const reviewTotal = computed(() => {
  return find(reportStore.issueList, { type: 'FOR_REVIEW' })?.count ?? 0
})

const onCheckBatchTransactions = () => {
  processTableRef.value.onCheckBatchTransactions()
}

const onResize = () => {
  reportStore.agGridApi?.sizeColumnsToFit({ columnLimits: [{ key: 'transactions-from-to', minWidth: 263 }] })
}

watch(
  route,
  async (newValue) => {
    try {
      if (newValue.name === 'reports-transactions-for-review') {
        loading.value = true
        reportStore.transactionFilter = {}
        tableConfigStore.processTableConfig = []
        reportStore.checkTransactionsAll = false
        reportStore.batchEditSelected = []
        await reportStore.fetchActivityEventList(entityId.value, 'FOR_REVIEW')
        await tableConfigStore.getProcessTableConfig('transactions', ['category', 'Operating'], 'right')
        if (route?.query?.noSet) {
          reportStore.transactionFilter.categoryNos = ['00000']
        } else {
          reportStore.transactionFilter.categoryNos = reportStore.activityEventList
        }
        const { data } = await ReportsApi.getActivityEvent(entityId.value, { status: 'REVIEWED' })
        const transactionFilter = {
          ...reportStore.transactionFilter,
          categoryNos: data
        }
        reportStore.processTableListDataInit(entityId.value, 'reviewed', {
          limit: 10,
          page: 1,
          ...transactionFilter
        })

        await reportStore.transactionsFilterDataInit(entityId.value)
        // emitter.emit('resetList')
        setTimeout(() => {
          loading.value = false
        }, 300)
      }
    } catch (error) {
      loading.value = false
    }
  },
  { immediate: true, deep: true }
)
onMounted(() => {
  window.addEventListener('resize', onResize)
})
onBeforeUnmount(() => {
  reportStore.batchReviewParams = {}
  window.removeEventListener('resize', onResize)
})
</script>
<style lang="scss" scoped>
.elv-transactions-review-done__title {
  font-size: 17px;
  line-height: 21px;
  color: #0e0f11;
  margin-top: 9px;
}

.elv-transactions-review-done__desc {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #838d95;
  margin-top: 8px;
  word-break: break-word;
}
</style>
